import { Easing } from 'motion';

type TransitionOptions = {
  transition: {
    duration: number;
    delay: number;
    ease: Easing | Easing[];
  };
  initial: {
    [key: string]: number | string;
  };
  animate: {
    [key: string]: number | string;
  };
};

export const DEFAULT_TRANSITION_DELAY = 0.15;

export const transitionOptions: TransitionOptions = {
  transition: {
    duration: 0.5,
    delay: DEFAULT_TRANSITION_DELAY,
    ease: 'easeInOut',
  },
  initial: {
    y: 5,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
} as const;
